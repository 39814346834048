import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import NewsletterSignup from "./newslettersignup"
import SocialMedia from "./socialmedia"
import FacebookIcon from "../images/icons/socialMediaFacebook.svg"
import InstagramIcon from "../images/icons/socialMediaInstagram.svg"
import TwitterIcon from "../images/icons/socialMediaTwitter.svg"
import YoutubeIcon from "../images/icons/socialMediaYouTube.svg"

const Footer = () => {

  const footerData = useStaticQuery(graphql`
    query {
      contentfulFooter {
        newsSignUpHeader
        newsSignUpSubHeader
        emailFieldText
        interestFieldText
        interestFieldOptions
        submitButtonText
        logo {
          file {
            url
          }
        }
        backgroundImageDesktop {
          fluid(maxWidth: 1920, quality: 100) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        backgroundImageMobile {
          fluid {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        navigationLinks {
          navigationLinks {
            pageName
            pageUrl
          }
        }
      }
      contentfulSocialMediaLinks {
        socialLinks {
          name
          url
        }
      }
    }
  `)

  const data = footerData.contentfulFooter

  const sources = [
    data.backgroundImageMobile.fluid,
    {
      ...data.backgroundImageDesktop.fluid,
      media: `(min-width: 768px)`,
    },
  ]
  
  let FacebookLink = ""
  let InstagramLink = ""
  let TwitterLink = ""
  let YouTubeLink = ""  

  const links = footerData.contentfulSocialMediaLinks.socialLinks

  links.forEach( link => {
    let linkName = link.name.toLowerCase()
    if (linkName === "facebook") {FacebookLink = link.url}
    if (linkName === "instagram") {InstagramLink = link.url}
    if (linkName === "twitter") {TwitterLink = link.url}
    if (linkName === "youtube") {YouTubeLink = link.url}
  })

  return (
    <BackgroundImage fluid={sources}>
      <div className="signup container mx-auto t-grid flex flex-col lg:flex-row items-center py-16 lg:py-0">
        <div className="col-start-1 col-span-4 lg:col-start-2 lg:col-span-5 space-y-5 flex flex-col">
          <h2 className="max-w-xs">{data.newsSignUpHeader}</h2>
          <p className="body-2">
            {data.newsSignUpSubHeader}
          </p>
        </div>
        <div className="col-start-1 col-span-4 lg:col-start-8 lg:col-span-4 flex flex-col items-center lg:items-start">
          <NewsletterSignup data={data} />
        </div>
      </div>
      <hr/>
      <footer className="">
        <div className="footer-top container mx-auto w-full t-grid flex flex-col lg:flex-row items-center py-12 lg:py-0">
          <div className="col-span-full lg:col-start-1 lg:col-end-3 flex justify-center hidden lg:block">
            <Link to="/">
              <img src={data.logo.file.url} alt="Radar Pictures Logo"></img>
            </Link>
          </div>
          <nav className="w-full col-start-2 col-span-2 lg:col-start-4 lg:col-span-6 flex flex-col lg:flex-row items-center justify-between h-40">
            {data.navigationLinks.navigationLinks.map(element => {
              return (
                <Link to={element.pageUrl} >
                <span className="footer-link">{element.pageName}</span>
                </Link>
              )
            })}
          </nav>
          <div className="col-span-full lg:col-start-1 lg:col-end-3 flex justify-center lg:hidden py-12">
            <img src={data.logo.file.url} alt="Radar Pictures Logo"></img>
          </div>
          <div className="col-start-1 col-span-4 lg:col-start-11 lg:col-span-2 flex justify-around ">
            <SocialMedia
              link={FacebookLink}
              icon={FacebookIcon}
              name="Facebook"
            />
            <SocialMedia
              link={InstagramLink}
              icon={InstagramIcon}
              name="Instagram"
            />
            <SocialMedia 
              link={TwitterLink} 
              icon={TwitterIcon} 
              name="Twitter"             
            />
            <SocialMedia 
              link={YouTubeLink} 
              icon={YoutubeIcon} 
              name="YouTube" 
            />
          </div>
        </div>
        <div className="bg-black">
          <div className="footer-bottom container mx-auto col-start-1 col-end-11 flex flex-col-reverse lg:flex-row justify-between items-center">
            <small className="legal py-6 lg:py-0">
              © Radar Pictures. {new Date().getFullYear()}. All rights reserved
            </small>
            <div className="flex space-x-10 pt-6 lg:py-0">
              <small className="legal">Terms & Conditions</small>
              <small className="legal">Privacy Policy</small>
            </div>
          </div>
        </div>
      </footer>
    </BackgroundImage>
  )
}

export default Footer
