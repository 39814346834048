/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useState } from "react"
import PropTypes from "prop-types"
import Header from "./header.js"
import Footer from "./footer.js"
import Sidebar from "./sidebar"
import "../styles/global.scss"

const Layout = ({ children }) => {
  const [sideBarOpen, setSideBarOpen] = useState(false)
  const toggleSideBar = () => {
    setSideBarOpen(!sideBarOpen)
  }

  const closeSideBar = () => {
    if (sideBarOpen){
      setSideBarOpen(!sideBarOpen)
    }
  }

  return (
    <div className="flex w-full bg-black">

      <div className="w-full">
        <Header toggleSideBar={toggleSideBar} />
        <main>{children}</main>
        <Footer />
      </div>
      <Sidebar closeSideBar={closeSideBar} sideBarOpen={sideBarOpen} />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
