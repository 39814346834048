import React, { useState, useRef } from "react"
import UseDetectOutsideClick from "./useDetectOutsideClick"

// converted from example at: https://codesandbox.io/s/checkboxbyplatform-ppmh4?file=/src/app.jsx

const NewsletterSignup = (props) => {
  const data = props.data
  const dropdownRef = useRef(null)
  const [expanded, setExpanded] = useState(false)
  const [selections, setSelections] = useState([])

  const toggleExpanded = () => {
    setExpanded(!expanded)
  }

  const closeExpanded = () => {
    if (expanded) {
      setExpanded(false)
    }
  }

  UseDetectOutsideClick(dropdownRef, closeExpanded)

  const handleChange = event => {
    if (event.target.checked) {
      return setSelections([...selections, event.target.name])
    }
    const filtered = selections.filter(name => name !== event.target.name)
    return setSelections(filtered)
  }

  return (
    <form
      name="news"
      method="post"
      data-netlify="true"
      className="flex flex-col space-y-5 w-full my-8"
      ref={dropdownRef}
    >
      <input type="hidden" name="form-name" value="news" />
      <input
        className="body-1 form-field"
        type="email"
        name="email"
        placeholder={data.emailFieldText}
        required
      />
      <div>
        <div
          onClick={toggleExpanded}
          onKeyDown={toggleExpanded}
          role="button"
          tabIndex="0"
          className="relative"
        >
          <div
            className={`body-1 form-field cursor-pointer overflow-x-hidden ${
              expanded ? "up-arrow" : "down-arrow"
            }`}
          >
            <div className="w-max">
              {selections.length
                ? selections.map((name, i) => (
                    <span key={i}>
                      {i ? ", " : null}
                      {name}
                    </span>
                  ))
                : `${data.interestFieldText}`}
            </div>
          </div>
        </div>

        <div className={"w-full relative " + (expanded ? "" : "hidden")}>
          <div className="absolute w-full bg-black z-10">
            {data.interestFieldOptions.map(option => (
              <label
                htmlFor={option}
                className="body-1 form-field flex justify-between checkbox"
                key={option}
              >
                {option}
                <input
                  id={option}
                  type="checkbox"
                  checked={selections.includes(option) ? true : false}
                  name={option}
                  value="interested"
                  onChange={handleChange}
                  className="cursor-pointer"
                />
              </label>
            ))}
          </div>
        </div>
      </div>
      <div>
        <input
          className="button-orange cta mt-6 cursor-pointer"
          type="submit"
          value={data.submitButtonText}
        />
      </div>
    </form>
  )
}

export default NewsletterSignup
