import React from "react"
import { Link } from "gatsby"
import RadarLogo from "../images/icons/radarLogo.svg"
import HamburgerImage from "../images/icons/hamburgerMenu.svg"

const Header = ({ toggleSideBar }) => {
  const onClick = () => {
    toggleSideBar()
  }
  return (
    <header className="absolute w-full z-10 mt-4 lg:mt-12">
      <div className="container mx-auto flex justify-between items-center">
        <Link to="/">
          <img
            src={RadarLogo}
            className="w-32 lg:w-auto"
            alt="radar pictures logo"
          />
        </Link>
        <button
          onClick={onClick}
          className="menu-trigger w-auto leading-3 cursor-pointer"
        >
          <img
            src={HamburgerImage}
            className="w-10 lg:w-auto p-2"
            alt="dropdown menu button"
          />
        </button>
      </div>
    </header>
  )
}

export default Header
