import React from "react"

export default props => {
  if (props.link) {
    return (
      <a target="_blank" rel="noopener noreferrer" href={props.link}>
        <img className="hover-orange" src={props.icon} alt={props.name} />
      </a>
    )
  } else {
    return ""
  }
}
