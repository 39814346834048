import React, { useRef } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import useDetectOutsideClick from "./useDetectOutsideClick"
import SocialMedia from "./socialmedia"
import CloseIcon from "../images/icons/closeIcon.svg"
import FacebookIcon from "../images/icons/socialMediaFacebook.svg"
import InstagramIcon from "../images/icons/socialMediaInstagram.svg"
import TwitterIcon from "../images/icons/socialMediaTwitter.svg"
import YoutubeIcon from "../images/icons/socialMediaYouTube.svg"

const Sidebar = (props) => {
  const sidebarRef = useRef(null)
  
  useDetectOutsideClick(sidebarRef, props.closeSideBar)
  
  const onClick = () => {
    props.closeSideBar()
  }

  const data = useStaticQuery(graphql`
    query {
      contentfulNavigationLinks {
        navigationLinks {
          pageName
          pageUrl
        }
      }
      contentfulSocialMediaLinks {
        socialLinks {
          name
          url
        }
      }
    }`)
  
  let FacebookLink = ""
  let InstagramLink = ""
  let TwitterLink = ""
  let YouTubeLink = ""
  
  const links = data.contentfulSocialMediaLinks.socialLinks

  links.forEach( link => {
    let linkName = link.name.toLowerCase()
    if (linkName === "facebook") {FacebookLink = link.url}
    if (linkName === "instagram") {InstagramLink = link.url}
    if (linkName === "twitter") {TwitterLink = link.url}
    if (linkName === "youtube") {YouTubeLink = link.url}
  })

  return (
    <nav
      ref={sidebarRef}
      className={
        "sidebar h-screen -top-4 lg:-top-12 " +
        (props.sideBarOpen
          ? "open w-full sm:w-96"
          : "closed w-0 text-gray")
      }
    >
      <div
        className={
          "sidebar fixed h-screen bg-sidebar bg-cover bg-black z-20 " +
          (props.sideBarOpen
            ? "open w-full sm:w-96 right-0"
            : "closed w-0 bottom-0 -right-16 bg-opacity-0")
        }
      >
        <div
          className={
            "sidebar fixed flex justify-end mt-4 lg:mt-12 right-10 xs:right-20 items-center h-10 z-30 " +
            (props.sideBarOpen ? "open" : "closed w-0")
          }
        >
          <button
            onClick={onClick}
            className={
              "sidebar " +
              (props.sideBarOpen ? "open" : "closed w-0 opacity-0")
            }
          >
            <img
              src={CloseIcon}
              alt="close-sidebar"
              className={
                "hover-orange " +
                (props.sideBarOpen ? "open p-1" : "closed w-0 opacity-0")
              }
            />
          </button>
        </div>
        <div
          className={
            "h-full flex flex-col justify-between items-center " +
            (props.sideBarOpen ? "open" : "closed opacity-0")
          }
        >
          <div />
          <div className="nav-text flex flex-col w-36 space-y-8 items-center">
            {data.contentfulNavigationLinks.navigationLinks.map(element => {
              return (
                <Link to={element.pageUrl}>
                <span className="nav">{element.pageName}</span>
                </Link>
              )
            })}
          </div>
          <div className="flex justify-evenly w-full pb-8">
            <SocialMedia
              link={FacebookLink}
              icon={FacebookIcon}
              name="Facebook"
            />
            <SocialMedia
              link={InstagramLink}
              icon={InstagramIcon}
              name="Instagram"
            />
            <SocialMedia 
              link={TwitterLink}
              icon={TwitterIcon}
              name="Twitter"
            />
            <SocialMedia
              link={YouTubeLink} 
              icon={YoutubeIcon} 
              name="YouTube"
            />
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Sidebar
